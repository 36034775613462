@import 'structure/base';
@import 'structure/reset';
@import 'structure/icon-variables';
@import 'structure/icon-style';

$font-base-url: '../assets/fonts/';

:export {
  checkRounded: $icon-icona-check-rounded;
}

/* Normal */
@font-face {
  font-family: 'ProText';
  font-weight: normal;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Text-Regular.ttf');
}

/* Light */
@font-face {
  font-family: 'ProText';
  font-weight: 400;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Text-Light.ttf');
}

/* Medium */
@font-face {
  font-family: 'ProText';
  font-weight: 500;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Text-Medium.ttf');
}

/* SemiBold */
@font-face {
  font-family: 'ProText';
  font-weight: 600;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Text-Semibold.ttf');
}

/* Bold */
@font-face {
  font-family: 'ProText';
  font-weight: 700;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Text-Bold.ttf');
}

/* Normal */
@font-face {
  font-family: 'ProDisplay';
  font-weight: normal;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Display-Regular.ttf');
}

/* Light */
@font-face {
  font-family: 'ProDisplay';
  font-weight: 400;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Display-Light.ttf');
}

/* Medium */
@font-face {
  font-family: 'ProDisplay';
  font-weight: 500;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Display-Medium.ttf');
}

/* SemiBold */
@font-face {
  font-family: 'ProDisplay';
  font-weight: 600;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Display-Semibold.ttf');
}

/* Bold */
@font-face {
  font-family: 'ProDisplay';
  font-weight: 700;
  font-style: normal;
  src: url($font-base-url+'SF-Pro-Display-Bold.ttf');
}

#root{
    padding-top: 60px;
}